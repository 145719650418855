// start by need section manual coding
window.sliderTimer3 = function sliderTimer3() {
    this.setInterval(currentSlidegreenauto, 4000);
}
window.addEventListener('load', () => {
    sliderTimer3();
})


window.currentSlidegreenauto = function currentSlidegreenauto() {
    var firstBlock = document.getElementById('firstgreen');
    var secondBlock = document.getElementById('secondgreen');
    var thirdBlock = document.getElementById('thirdgreen');
    var fourBlock = document.getElementById('fourgreen');
    var fiveBlock = document.getElementById('fivegreen');
    var sixBlock = document.getElementById('sixgreen');

    var oneBlock = document.getElementById('1green');
    var twoBlock = document.getElementById('2green');
    var threeBlock = document.getElementById('3green');
    var foBlock = document.getElementById('4green');
    var fiBlock = document.getElementById('5green');
    var siBlock = document.getElementById('6green');

    var firstdiv = document.getElementById("swipeGreen").firstElementChild;
    var lastdiv = document.getElementById("swipeGreen").lastElementChild;
    var listachievement = document.getElementById("swipeGreen"); // Get the <ul> element to insert a new node
    lastdiv.insertAdjacentElement("afterend", firstdiv);
    var needid = listachievement.children[0].id;

    var isPaused = false;
    if (!window.isPaused) {
        if (needid === 'firstgreen') {
            firstBlock.style.display = 'flex';
            secondBlock.style.display = 'none';
            thirdBlock.style.display = 'none';
            fourBlock.style.display = 'none';
            fiveBlock.style.display = 'none';
            sixBlock.style.display = 'none';

            oneBlock.classList.add('dotgreenactive');
            twoBlock.classList.remove('dotgreenactive');
            threeBlock.classList.remove('dotgreenactive');
            foBlock.classList.remove('dotgreenactive');
            fiBlock.classList.remove('dotgreenactive');
            siBlock.classList.remove('dotgreenactive');


        } else if (needid === 'secondgreen') {
            firstBlock.style.display = 'none';
            secondBlock.style.display = 'flex';
            thirdBlock.style.display = 'none';
            fourBlock.style.display = 'none';
            fiveBlock.style.display = 'none';
            sixBlock.style.display = 'none';

            oneBlock.classList.remove('dotgreenactive');
            twoBlock.classList.add('dotgreenactive');
            threeBlock.classList.remove('dotgreenactive');
            foBlock.classList.remove('dotgreenactive');
            fiBlock.classList.remove('dotgreenactive');
            siBlock.classList.remove('dotgreenactive');

        } else if (needid === 'thirdgreen') {
            firstBlock.style.display = 'none';
            secondBlock.style.display = 'none';
            thirdBlock.style.display = 'flex';
            fourBlock.style.display = 'none';
            fiveBlock.style.display = 'none';
            sixBlock.style.display = 'none';

            oneBlock.classList.remove('dotgreenactive');
            twoBlock.classList.remove('dotgreenactive');
            threeBlock.classList.add('dotgreenactive');
            foBlock.classList.remove('dotgreenactive');
            fiBlock.classList.remove('dotgreenactive');
            siBlock.classList.remove('dotgreenactive');

        } else if (needid === 'fourgreen') {
            firstBlock.style.display = 'none';
            secondBlock.style.display = 'none';
            thirdBlock.style.display = 'none';
            fourBlock.style.display = 'flex';
            fiveBlock.style.display = 'none';
            sixBlock.style.display = 'none';

            oneBlock.classList.remove('dotgreenactive');
            twoBlock.classList.remove('dotgreenactive');
            threeBlock.classList.remove('dotgreenactive');
            foBlock.classList.add('dotgreenactive');
            fiBlock.classList.remove('dotgreenactive');
            siBlock.classList.remove('dotgreenactive');

        } else if (needid === 'fivegreen') {
            firstBlock.style.display = 'none';
            secondBlock.style.display = 'none';
            thirdBlock.style.display = 'none';
            fourBlock.style.display = 'none';
            fiveBlock.style.display = 'flex';
            sixBlock.style.display = 'none';


            oneBlock.classList.remove('dotgreenactive');
            twoBlock.classList.remove('dotgreenactive');
            threeBlock.classList.remove('dotgreenactive');
            foBlock.classList.remove('dotgreenactive');
            fiBlock.classList.add('dotgreenactive');
            siBlock.classList.remove('dotgreenactive');

        } else if (needid === 'sixgreen') {
            firstBlock.style.display = 'none';
            secondBlock.style.display = 'none';
            thirdBlock.style.display = 'none';
            fourBlock.style.display = 'none';
            fiveBlock.style.display = 'none';
            sixBlock.style.display = 'flex';

            oneBlock.classList.remove('dotgreenactive');
            twoBlock.classList.remove('dotgreenactive');
            threeBlock.classList.remove('dotgreenactive');
            foBlock.classList.remove('dotgreenactive');
            fiBlock.classList.remove('dotgreenactive');
            siBlock.classList.add('dotgreenactive');

        }
    }
}

window.currentSlidegreen = function currentSlidegreen(id) {
    var firstBlock = document.getElementById('firstgreen');
    var secondBlock = document.getElementById('secondgreen');
    var thirdBlock = document.getElementById('thirdgreen');
    var fourBlock = document.getElementById('fourgreen');
    var fiveBlock = document.getElementById('fivegreen');
    var sixBlock = document.getElementById('sixgreen');

    var oneBlock = document.getElementById('1green');
    var twoBlock = document.getElementById('2green');
    var threeBlock = document.getElementById('3green');
    var foBlock = document.getElementById('4green');
    var fiBlock = document.getElementById('5green');
    var siBlock = document.getElementById('6green');
    var isPaused = false;

    if (!window.isPaused) {
        if (id === "1green") {
            firstBlock.style.display = 'flex';
            secondBlock.style.display = 'none';
            thirdBlock.style.display = 'none';
            fourBlock.style.display = 'none';
            fiveBlock.style.display = 'none';
            sixBlock.style.display = 'none';

            oneBlock.classList.add('dotgreenactive');
            twoBlock.classList.remove('dotgreenactive');
            threeBlock.classList.remove('dotgreenactive');
            foBlock.classList.remove('dotgreenactive');
            fiBlock.classList.remove('dotgreenactive');
            siBlock.classList.remove('dotgreenactive');


        } else if (id === "2green") {
            firstBlock.style.display = 'none';
            secondBlock.style.display = 'flex';
            thirdBlock.style.display = 'none';
            fourBlock.style.display = 'none';
            fiveBlock.style.display = 'none';
            sixBlock.style.display = 'none';

            oneBlock.classList.remove('dotgreenactive');
            twoBlock.classList.add('dotgreenactive');
            threeBlock.classList.remove('dotgreenactive');
            foBlock.classList.remove('dotgreenactive');
            fiBlock.classList.remove('dotgreenactive');
            siBlock.classList.remove('dotgreenactive');

        } else if (id === "3green") {
            firstBlock.style.display = 'none';
            secondBlock.style.display = 'none';
            thirdBlock.style.display = 'flex';
            fourBlock.style.display = 'none';
            fiveBlock.style.display = 'none';
            sixBlock.style.display = 'none';

            oneBlock.classList.remove('dotgreenactive');
            twoBlock.classList.remove('dotgreenactive');
            threeBlock.classList.add('dotgreenactive');
            foBlock.classList.remove('dotgreenactive');
            fiBlock.classList.remove('dotgreenactive');
            siBlock.classList.remove('dotgreenactive');

        } else if (id === "4green") {
            firstBlock.style.display = 'none';
            secondBlock.style.display = 'none';
            thirdBlock.style.display = 'none';
            fourBlock.style.display = 'flex';
            fiveBlock.style.display = 'none';
            sixBlock.style.display = 'none';

            oneBlock.classList.remove('dotgreenactive');
            twoBlock.classList.remove('dotgreenactive');
            threeBlock.classList.remove('dotgreenactive');
            foBlock.classList.add('dotgreenactive');
            fiBlock.classList.remove('dotgreenactive');
            siBlock.classList.remove('dotgreenactive');

        } else if (id === "5green") {
            firstBlock.style.display = 'none';
            secondBlock.style.display = 'none';
            thirdBlock.style.display = 'none';
            fourBlock.style.display = 'none';
            fiveBlock.style.display = 'flex';
            sixBlock.style.display = 'none';


            oneBlock.classList.remove('dotgreenactive');
            twoBlock.classList.remove('dotgreenactive');
            threeBlock.classList.remove('dotgreenactive');
            foBlock.classList.remove('dotgreenactive');
            fiBlock.classList.add('dotgreenactive');
            siBlock.classList.remove('dotgreenactive');

        } else if (id === "6green") {
            firstBlock.style.display = 'none';
            secondBlock.style.display = 'none';
            thirdBlock.style.display = 'none';
            fourBlock.style.display = 'none';
            fiveBlock.style.display = 'none';
            sixBlock.style.display = 'flex';

            oneBlock.classList.remove('dotgreenactive');
            twoBlock.classList.remove('dotgreenactive');
            threeBlock.classList.remove('dotgreenactive');
            foBlock.classList.remove('dotgreenactive');
            fiBlock.classList.remove('dotgreenactive');
            siBlock.classList.add('dotgreenactive');

        }
    }
}
// start by need section manual coding

var mySwipeG = document.getElementById('swipeGreen');
if (mySwipeG) {
    var mySwipeeG = new Hammer(mySwipeG, {
        touchAction: "pan-y"
    });

    mySwipeeG.get('pan').set({
        direction: Hammer.DIRECTION_ALL
    });

    var idG = '';


    mySwipeeG.on("swipeleft swiperight", function (ev) {
        var firstBlock = document.getElementById('firstgreen');
        var secondBlock = document.getElementById('secondgreen');
        var thirdBlock = document.getElementById('thirdgreen');
        var fourBlock = document.getElementById('fourgreen');
        var fiveBlock = document.getElementById('fivegreen');
        var sixBlock = document.getElementById('sixgreen');

        var oneBlock = document.getElementById('1green');
        var twoBlock = document.getElementById('2green');
        var threeBlock = document.getElementById('3green');
        var foBlock = document.getElementById('4green');
        var fiBlock = document.getElementById('5green');
        var siBlock = document.getElementById('6green');

        // console.log("HERE :: ",ev)
        console.log("HERE :: ", ev.type)
        elementt = document.getElementsByClassName("dotgreenactive")[0];
        console.log("My Element :: ", elementt)

        idG = elementt.id;
        console.log("My iDD :: ", idG)
        if (ev.type === 'swipeleft') {

            if (idG === '1green') {
                firstBlock.style.display = 'none';
                secondBlock.style.display = 'flex';
                thirdBlock.style.display = 'none';
                fourBlock.style.display = 'none';
                fiveBlock.style.display = 'none';
                sixBlock.style.display = 'none';

                oneBlock.classList.remove('dotgreenactive');
                twoBlock.classList.add('dotgreenactive');
                threeBlock.classList.remove('dotgreenactive');
                foBlock.classList.remove('dotgreenactive');
                fiBlock.classList.remove('dotgreenactive');
                siBlock.classList.remove('dotgreenactive');


            } else if (idG === '2green') {
                firstBlock.style.display = 'none';
                secondBlock.style.display = 'none';
                thirdBlock.style.display = 'flex';
                fourBlock.style.display = 'none';
                fiveBlock.style.display = 'none';
                sixBlock.style.display = 'none';

                oneBlock.classList.remove('dotgreenactive');
                twoBlock.classList.remove('dotgreenactive');
                threeBlock.classList.add('dotgreenactive');
                foBlock.classList.remove('dotgreenactive');
                fiBlock.classList.remove('dotgreenactive');
                siBlock.classList.remove('dotgreenactive');


            } else if (idG === '3green') {
                firstBlock.style.display = 'none';
                secondBlock.style.display = 'none';
                thirdBlock.style.display = 'none';
                fourBlock.style.display = 'flex';
                fiveBlock.style.display = 'none';
                sixBlock.style.display = 'none';

                oneBlock.classList.remove('dotgreenactive');
                twoBlock.classList.remove('dotgreenactive');
                threeBlock.classList.remove('dotgreenactive');
                foBlock.classList.add('dotgreenactive');
                fiBlock.classList.remove('dotgreenactive');
                siBlock.classList.remove('dotgreenactive');



            } else if (idG === '4green') {
                firstBlock.style.display = 'none';
                secondBlock.style.display = 'none';
                thirdBlock.style.display = 'none';
                fourBlock.style.display = 'none';
                fiveBlock.style.display = 'flex';
                sixBlock.style.display = 'none';

                oneBlock.classList.remove('dotgreenactive');
                twoBlock.classList.remove('dotgreenactive');
                threeBlock.classList.remove('dotgreenactive');
                foBlock.classList.remove('dotgreenactive');
                fiBlock.classList.add('dotgreenactive');
                siBlock.classList.remove('dotgreenactive');



            } else if (idG === '5green') {
                firstBlock.style.display = 'none';
                secondBlock.style.display = 'none';
                thirdBlock.style.display = 'none';
                fourBlock.style.display = 'none';
                fiveBlock.style.display = 'none';
                sixBlock.style.display = 'flex';

                oneBlock.classList.remove('dotgreenactive');
                twoBlock.classList.remove('dotgreenactive');
                threeBlock.classList.remove('dotgreenactive');
                foBlock.classList.remove('dotgreenactive');
                fiBlock.classList.remove('dotgreenactive');
                siBlock.classList.add('dotgreenactive');



            } else if (idG === '6green') {
                firstBlock.style.display = 'flex';
                secondBlock.style.display = 'none';
                thirdBlock.style.display = 'none';
                fourBlock.style.display = 'none';
                fiveBlock.style.display = 'none';
                sixBlock.style.display = 'none';

                oneBlock.classList.add('dotgreenactive');
                twoBlock.classList.remove('dotgreenactive');
                threeBlock.classList.remove('dotgreenactive');
                foBlock.classList.remove('dotgreenactive');
                fiBlock.classList.remove('dotgreenactive');
                siBlock.classList.remove('dotgreenactive');



            }


        }

        if (ev.type === 'swiperight') {
            if (idG === '1green') {
                firstBlock.style.display = 'none';
                secondBlock.style.display = 'none';
                thirdBlock.style.display = 'none';
                fourBlock.style.display = 'none';
                fiveBlock.style.display = 'none';
                sixBlock.style.display = 'flex';

                oneBlock.classList.remove('dotgreenactive');
                twoBlock.classList.remove('dotgreenactive');
                threeBlock.classList.remove('dotgreenactive');
                foBlock.classList.remove('dotgreenactive');
                fiBlock.classList.remove('dotgreenactive');
                siBlock.classList.add('dotgreenactive');


            } else if (idG === '2green') {
                firstBlock.style.display = 'flex';
                secondBlock.style.display = 'none';
                thirdBlock.style.display = 'none';
                fourBlock.style.display = 'none';
                fiveBlock.style.display = 'none';
                sixBlock.style.display = 'none';

                oneBlock.classList.add('dotgreenactive');
                twoBlock.classList.remove('dotgreenactive');
                threeBlock.classList.remove('dotgreenactive');
                foBlock.classList.remove('dotgreenactive');
                fiBlock.classList.remove('dotgreenactive');
                siBlock.classList.remove('dotgreenactive');


            } else if (idG === '3green') {
                firstBlock.style.display = 'none';
                secondBlock.style.display = 'flex';
                thirdBlock.style.display = 'none';
                fourBlock.style.display = 'none';
                fiveBlock.style.display = 'none';
                sixBlock.style.display = 'none';

                oneBlock.classList.remove('dotgreenactive');
                twoBlock.classList.add('dotgreenactive');
                threeBlock.classList.remove('dotgreenactive');
                foBlock.classList.remove('dotgreenactive');
                fiBlock.classList.remove('dotgreenactive');
                siBlock.classList.remove('dotgreenactive');



            } else if (idG === '4green') {
                firstBlock.style.display = 'none';
                secondBlock.style.display = 'none';
                thirdBlock.style.display = 'flex';
                fourBlock.style.display = 'none';
                fiveBlock.style.display = 'none';
                sixBlock.style.display = 'none';

                oneBlock.classList.remove('dotgreenactive');
                twoBlock.classList.remove('dotgreenactive');
                threeBlock.classList.add('dotgreenactive');
                foBlock.classList.remove('dotgreenactive');
                fiBlock.classList.remove('dotgreenactive');
                siBlock.classList.remove('dotgreenactive');



            } else if (idG === '5green') {
                firstBlock.style.display = 'none';
                secondBlock.style.display = 'none';
                thirdBlock.style.display = 'none';
                fourBlock.style.display = 'flex';
                fiveBlock.style.display = 'none';
                sixBlock.style.display = 'none';

                oneBlock.classList.remove('dotgreenactive');
                twoBlock.classList.remove('dotgreenactive');
                threeBlock.classList.remove('dotgreenactive');
                foBlock.classList.add('dotgreenactive');
                fiBlock.classList.remove('dotgreenactive');
                siBlock.classList.remove('dotgreenactive');



            } else if (idG === '6green') {
                firstBlock.style.display = 'none';
                secondBlock.style.display = 'none';
                thirdBlock.style.display = 'none';
                fourBlock.style.display = 'none';
                fiveBlock.style.display = 'flex';
                sixBlock.style.display = 'none';

                oneBlock.classList.remove('dotgreenactive');
                twoBlock.classList.remove('dotgreenactive');
                threeBlock.classList.remove('dotgreenactive');
                foBlock.classList.remove('dotgreenactive');
                fiBlock.classList.add('dotgreenactive');
                siBlock.classList.remove('dotgreenactive');



            }

        }
    });
}